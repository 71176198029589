import { useEffect, useState } from 'react';

import { VenueContact } from 'src/types/classic';
import { getVenueContact } from 'src/utils/classic-api';

export const useStaffUser = (userId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [staffUser, setStaffUser] = useState<VenueContact>();

  useEffect(() => {
    fetchUser(userId);

    async function fetchUser(id: string) {
      if (!id) return;
      setLoading(true);

      try {
        const res = await getVenueContact(userId);
        setStaffUser(res);
      } catch (err) {
        setError(err as Error);
      }
      setLoading(false);
    }
  }, [userId]);

  return {
    loading,
    staffUser,
    error,
  };
};
