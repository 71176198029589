import React from 'react';

import { useTranslation } from 'react-i18next';
import ErrorPanel from 'src/components/error-panel/error-panel';
import Spinner from 'src/components/spinner/spinner';
import StaffUserForm from 'src/components/staff-user-form/staff-user-form';
import Redirect from 'src/components/util-components/redirect';
import ROUTES from 'src/utils/routes';

import { useStaffUser } from './hooks';

interface Props {
  staffId?: string;
}

const EditStaffUser: React.FC<Props> = ({ staffId }) => {
  const { t } = useTranslation();
  const { staffUser, loading, error } = useStaffUser(staffId ?? '');

  if (!staffId) return <Redirect to={ROUTES.NOT_FOUND} />;
  if (error) return <ErrorPanel subtitle={t('staffUserModule.queryErrors.fetchStaffUser')} />;
  if (loading || !staffUser) return <Spinner />;

  return <StaffUserForm existingStaffUser={staffUser} />;
};

export default EditStaffUser;
