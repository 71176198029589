import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditStaffUser from 'src/components/edit-staff-user/edit-staff-user';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EditStaffUserPage = () => {
  return (
    <Router basepath="/staff/edit-staff">
      <EditStaffUserRoute path="/:staffId" />
    </Router>
  );
};

interface EditStaffUserRouteProps extends RouteComponentProps {
  staffId?: string;
}

const EditStaffUserRoute: React.FC<EditStaffUserRouteProps> = ({ staffId }) => {
  return (
    <Layout>
      <SEO title="Edit Staff User" />
      <PrivateRoute>
        <EditStaffUser staffId={staffId} />
      </PrivateRoute>
    </Layout>
  );
};

export default EditStaffUserPage;
